import * as React from 'react';
import {cn} from '~/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'default' | 'success' | 'warning' | 'error';
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({className, type, variant = 'default', ...props}, ref) => {
    const variantClasses = {
      default: 'border-input bg-background/30 placeholder:text-muted-foreground',
      success: 'border-success bg-success/10 placeholder:text-success-foreground',
      warning: 'border-warning bg-warning/10 placeholder:text-warning-foreground',
      error: 'border-error bg-error/10 placeholder:text-error-foreground',
    };

    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-full px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          variantClasses[variant],
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export {Input};
